.come-from-modal .product-image-order-page {
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
  margin-right: 10px;
}

.come-from-modal .product-details-order {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px !important;
}

.come-from-modal .proceed-to-checkout {
  width: 100%;
  background: #4fb68d !important;
  color: #fff !important;
  border-color: #4fb68d !important;
  border-radius: 0 !important;
}

.come-from-modal .img-container {
  position: relative;
}

.come-from-modal .img-container span {
  position: absolute;
  top: 1px;
  right: 5px;
  /* background-color: orangered; */
  color: #ff4500;
  border-radius: 25px;
  width: 18px;
  display: inline-block;
  text-align: center;
  height: 18px;
  font-size: 12px;
  cursor: pointer;
}

.come-from-modal-top {
  margin-top: 66px !important;
}

.come-from-modal-top.right .modal-body {
  max-height: calc(100vh - 190px) !important;
}

.no-items-cart-mobile {
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 15px;
  color: orangered;
}
.modal-footer {
  justify-content: center !important;
}
.model-text {
  font-size: 16px;
  color: #fff;
}
.input-group-button {
  padding-left: 12px;
  padding-right: 12px;
}
.cartItem {
  display: flex;
  justify-content: center;
}

.plus-minus-input {
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.plus-minus-input .input-group-field {
  text-align: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 1rem;
}

.plus-minus-input .input-group-field::-webkit-inner-spin-button,
.plus-minus-input .input-group-field ::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.plus-minus-input .input-group-button .circle {
  border-radius: 50%;
  padding: 5px;
  background-color: #ffff;
  border: solid 1px #009688;
}
