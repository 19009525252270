#cap-one {
  text-align: center !important;
  right: 40% !important;
  top: 15% !important;
}
.home-banner-carousel .carousel-caption {
  position: absolute;
  right: 15%;
  left: auto;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  top: 25%;
  max-width: 40%;
}

.home-banner-carousel .carousel-caption h3 {
  /* color: #000;
  font-weight: bold;
  font-size: 25px; */
  color: #000;
  font-weight: bold;
  font-size: 24px;
  line-height: 2;
  font-family: "here-just";
}

.home-banner-carousel .carousel-caption .shop-now-btn {
  padding: 0 15px !important;
  line-height: 34px !important;
  height: 32px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border: none !important;
  border-radius: 25px !important;
  box-shadow: none !important;
  text-transform: uppercase !important;
  background: #4fb68d !important;
  display: inline-block !important;
  color: #fff !important;
  width: 125px;
}
.home-banner-carousel-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .home-banner-carousel-mobile {
    display: block;
  }
  .home-banner-carousel {
    display: none;
  }
  .home-banner-carousel .carousel-caption {
    display: none;
  }
  .carousel-caption {
    bottom: 65px !important;
  }
  .home-banner-carousel-mobile .carousel-caption {
    color: #fff;
    text-align: center;
    z-index: 1 !important;
    font-family: "here-just";
  }

  .home-banner-carousel-mobile .carousel-caption h3 {
    color: #6a6649;
    font-weight: bold;
    font-size: 24px;
    line-height: 2;
    font-family: "here-just";
  }

  .home-banner-carousel-mobile .carousel-caption .shop-now-btn {
    padding: 0 15px !important;
    line-height: 34px !important;
    height: 32px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    border: none !important;
    border-radius: 25px !important;
    box-shadow: none !important;
    text-transform: uppercase !important;
    background: #4fb68d !important;
    display: inline-block !important;
    color: #fff !important;
    width: 125px;
  }
}
