.single-static-organic-wrap {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 10px !important;
}
.organic-header {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 25px;
}
.img-responsive {
  margin: 10px;
  height: 75%;
}
.single-static-organic h4 {
  color: #253237;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 22px;
  margin-bottom: 0;
  text-align: center;
}
.single-static-organic p {
  line-height: 20px;
  color: #666;
  font-size: 13px;
  margin: 0;
}
.single-static-organic img {
  margin: 0;
}
.single-static-organic {
  position: relative;
  padding: 36px 5px;
}
.single-static-organic-meta {
  overflow: hidden;
}
.single-static-organic-meta h4{
  color: #155e45;
  min-height: 20px !important;
}
.single-static-organic-wrap {
  /* border: 1px solid#2E705A; */
  border-radius: 0px 5px 5px 0px;
  padding: 0 20px;
  background-color: #E1F8DC;
}
