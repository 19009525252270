.cart-table thead th {
  background-color: #f9f9f9;
  color: #253237;
  border: 2px solid #f9f9f9;
  padding: 21px 45px 22px;
  font-size: 14px;
  font-weight: 700 !important;
  text-align: center;
  text-transform: uppercase;
}
.cart-table-content table tbody > tr {
  border-bottom: 1px solid #ebebeb !important;
}
.cart-table tbody td {
  background-color: #fff;
  color: #666;
  border: 2px solid #f9f9f9;
  padding: 30px 0;
  font-weight: 500 !important;
  text-align: center;
}

.remove-item-from-cart {
  background-color: #fff !important;
  color: #202932 !important;
  border: 1px solid #fff !important;
  padding: 5px !important;
  width: 35px !important;
  border-radius: 2px !important;
}
.remove-item-from-cart:hover {
  color: #4fb68d !important;
}

.checkout-btn {
  background-color: #4fb68b !important;
  border-radius: 5px !important;
  display: inline-block;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1;
  padding: 18px 63px 17px !important;
  text-transform: uppercase;
  border: 1px solid #fff !important;
  color: #fff !important;
}

.checkout-btn:hover {
  background-color: #4fb68d !important;
  color: #fff !important;
}

.checkout-btn-container {
  text-align: right;
}

.empty-cart {
  text-align: center;
}

.mobile-order-details {
  display: none;
}

.mobile-order-details .product-image-order-page {
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
  margin-right: 10px;
}

.mobile-order-details .product-details-order {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px !important;
}

.mobile-order-details .img-container {
  position: relative;
}

.mobile-order-details .img-container span {
  position: absolute;
  top: 2px;
  right: 10px;
  background-color: orangered;
  color: #fff;
  border-radius: 25px;
  width: 18px;
  display: inline-block;
  text-align: center;
  height: 18px;
  font-size: 12px;
  cursor: pointer;
}

.cart-total-td {
  text-align: right !important;
  padding-right: 25px !important;
  font-weight: bold !important;
  color: #4fb68d !important;
  font-size: 20px !important;
}

.cart-total-td-price {
  font-weight: bold !important;
  color: #4fb68d !important;
  font-size: 20px !important;
}

@media only screen and (max-width: 768px) {
  .mobile-order-details {
    display: inline-block;
  }

  .desktop-order-details {
    display: none;
  }

  .checkout-btn {
    width: 100% !important;
    padding: 15px 5px !important;
  }
}
.input-group-button {
  padding-left: 12px;
  padding-right: 12px;
}
.cartItem {
  display: flex;
  justify-content: center;
}

.plus-minus-input {
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.plus-minus-input .input-group-field {
  text-align: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 1rem;
}

.plus-minus-input .input-group-field::-webkit-inner-spin-button,
.plus-minus-input .input-group-field ::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.plus-minus-input .input-group-button .circle {
  border-radius: 15%;
  padding: 5px;
  background-color: #ffff;
  border: solid 1px #009688;
}
