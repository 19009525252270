.breadcrumb-area-about {
  text-align: center;
  position: relative;
  padding: 110px 0;
  background: url(../../assets/images/our-story.png) no-repeat;
  background-size: cover;
  min-height: 395px;
  color: #fff !important;
}


.breadcrumb-links li a:after {
  font-size: 13px;
  line-height: 24px;
  display: block;
  font-weight: 400;
  top: 0px;
  right: -20px;
  position: absolute;
  content: "\1F872";
  /* font-family: "Ionicons"; */
}

.breadcrumb_container .breadcrumb ol li:after {
  color: #253237;
  margin: 0 10px;
  vertical-align: -1px;
}

.breadcrumb-links li {
  display: inline-block;
}

.breadcrumb-links li a {
  display: inline-block;
  position: relative;
  color: #253237;
  line-height: 20px;
  margin-right: 25px;
}

.breadcrumb-links li a:hover {
  color: #4fb68d;
}
.about-area {
  margin-top: 50px;
  margin-bottom: 50px;
}
.header-page {
  font-weight: 700;
  text-align: center;
}

.about-area p {
    text-align: justify;
    color: #888;
    font-size: 15px;
    font-weight: 400;
    line-height: 2;
    margin: 0;
}

.numbers .item {
  position: relative;
}

.numbers .item:after {
  width: 35px;
  height: 35px;
  bottom: 0;
  background: #f7f7f7;
}

.numbers .item:before {
  width: 0;
  height: 0;
  border-top: 35px solid transparent;
  border-right: 35px solid #f7f7f7;
  bottom: 35px;
}

.numbers .item:before,
.numbers .item:after {
  content: '';
  position: absolute;
  right: 20%;
  z-index: -1;
}

.numbers .item h3 {
  margin-bottom: 10px;
  line-height: 1.4;
  font-weight: 600;
  padding: 8px;
}

.numbers .item h3 span {
  /* font-size: 60px; */
  font-size: 45px;
  color: #b19777;
}

.numbers .item h3 i {
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  color: #777;
  margin-left: 15px;
}

.numbers .item h6 {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.custom-font{
  font-family: 'Teko', sans-serif;
  font-weight: 400;
}

.mb-20{
  margin-bottom: 20px !important;
}
p{
  color: #888;
  font-size: 15px;
  font-weight: 400;
  line-height: 2;
  margin: 0;
}
.about-header{
  font-size: 25px;
  margin: 0;
  line-height: 1.4;
  font-weight: 600;
}
.mt-44{
 margin-top: 44px !important;
}


.services.halfbg .background {
  height: 100vh;
}

.services.halfbg .ontop {
  position: relative;
  margin-top: -120px;
  z-index: 3;
}

.services.halfbg .ontop .item-bx:hover {
  background: #b19777;
}

.services.halfbg .ontop .item-bx:hover .numb,
.services.halfbg .ontop .item-bx:hover .more {
  color: #fff;
}

.play-button {
  position: relative;
  width: 85px;
  height: 85px;
  margin: auto;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  z-index: 4;
}

.play-button svg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.background{
  background-image: url(../../assets/images/play.png);
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center 36.4px;
  background-size: 100%;
}
.mt-10{
  margin-top: 14px !important;
}