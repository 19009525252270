.forgotpass-link {
  float: right;
  color: #4fb68d !important;
}

.forgotpass-link:hover {
  color: #4fb68d !important;
  text-decoration: none !important;
}

.not-register-link {
  margin-top: 25px;
  text-align: center;
  border: 1px solid #f5f5f5;
  padding: 8px;
  font-size: 15px;
}

.not-register-link p {
  margin-bottom: 0;
}

.not-register-link a {
  color: #4fb68d;
}

.not-register-link a:hover {
  text-decoration: none;
  color: #4fb68d;
}
