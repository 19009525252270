.contact-map #mapid {
  height: 560px;
}
.custom-row-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.custom-row-2 div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}
.contact-info-wrap {
  padding: 40px;
  background: #f9f9f9 none repeat scroll 0 0;
  box-shadow: 0 0 40px rgb(24 25 25 / 13%);
}
.contact-info-wrap .single-contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
}
.contact-info-wrap .single-contact-info .contact-icon {
  margin-right: 20px;
}
.contact-info-wrap .single-contact-info .contact-icon i {
  font-size: 20px;
  color: #253237;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #252525;
  text-align: center;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.contact-info-wrap .single-contact-info .contact-info-dec p {
  line-height: 1;
  color: #253237;
  margin: 0 0 9px;
}
.contact-info-wrap .single-contact-info .contact-info-dec p a {
  color: #253237;
}
.contact-info-wrap .single-contact-info .contact-info-dec p a:hover {
  color: #4fb68d;
}
.contact-info-wrap .single-contact-info:hover .contact-icon i {
  background-color: #4fb68d;
  color: #fff;
  border-color: #4fb68d;
}
.contact-social h3 {
  font-weight: 700;
  color: #253237;
  font-size: 24px;
  margin: 0 0 17px;
  line-height: 1;
}
.contact-social ul li a {
  color: #666;
}
.contact-social .social-info li a {
  margin-right: 15px;
}

.contact-form {
  background: #f9f9f9 none repeat scroll 0 0;
  box-shadow: 0 0 40px rgb(24 25 25 / 13%);
  padding: 44px;
  padding-bottom: 12px;

}
.contact-form .contact-title h2 {
  font-size: 24px;
  font-weight: 700;
  color: #253237;
  line-height: 1;
  margin-bottom: 36px;
  margin-left: -10px;
}
.contact-form .contact-form-style input,
.contact-form .contact-form-style textarea {
  background: transparent;
  border: 1px solid #c1c1c1;
  height: 40px;
  padding: 2px 14px;
  margin-bottom: 30px;
  color: #000;
  width: 100%;
  outline: none;
}
.contact-form p {
  color: #333;
}
.contact-form .contact-form-style textarea {
  padding: 20px 14px;
  margin-bottom: 0px;
  height: 200px;
}
.contact-map {
  margin-bottom: 60px;
}
.contact-form .contact-form-style button {
  border: none;
  background-color: #4fb68d !important;
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  padding: 15px 52px;
  margin-top: 33px;
  outline: none;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 30px;
}
.contact-form .contact-form-style button:hover {
  background-color: #253237;
  color: #fff;
}

