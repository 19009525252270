.featured-categories-container {
  margin-top: 50px;
}

.view-cat-product-btn {
  height: 0px !important;
  line-height: 0px !important;
  text-align: center !important;
  display: inline-block !important;
  background-color: transparent !important;
  color: #253237 !important;
  border-radius: 30px !important;
  font-size: 14px !important;
  padding: 0 0 0 10px !important;
  border-color: transparent !important;
  color: #000 !important;
  transition: all 300ms linear;
}

.view-cat-product-btn:hover {
  padding-right: 15px !important;
  transition: all 300ms linear;
  color: #4fb68d !important;
}

.categories-card .card-img {
  max-height: 180px;
}

.categories-card .card {
  border-radius: 2px !important;
  border: none !important;
  margin-bottom: 20px;
  overflow: hidden;
}

.categories-card .card-img {
  transition: 0.5s all ease-in-out;
}

.categories-card:hover .card-img {
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

.align-items-right {
  align-items: flex-end !important;
}
