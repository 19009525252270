.product-carousel {
  margin-top: 50px;
}

.owl-nav {
  position: absolute;
  top: -115px !important;
  border: 0;
  right: 0;
}

.featured-products-container {
  margin-top: 50px;
}

.owl-carousel .owl-nav button.owl-prev span,
.owl-carousel .owl-nav button.owl-next span {
  font-size: 50px;
}

.list-product {
  position: relative;
  background: #fff;
  z-index: 1;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.list-product:hover {
  border-color: #4fb68d;
}

.list-product .inner-link {
  font-size: 12px;
  text-transform: uppercase;
  color: #999;
  line-height: 1;
  margin-bottom: 10px;
  display: block;
}

.list-product .inner-link:hover {
  color: #999;
}

.list-product .product-link {
  line-height: 18px;
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
  color: #253237;
  font-size: 14px;
  text-transform: capitalize;
}

.list-product .product-link:hover {
  color: #4fb68d;
}

.list-product .current-price {
  color: #cf2929;
  font-weight: 400;
  margin-right: 2px;
  font-size: 14px;
}

.view-product-btn {
  height: 30px !important;
  line-height: 30px !important;
  text-align: center !important;
  display: inline-block !important;
  background-color: #253237 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 30px !important;
  font-size: 14px !important;
  padding: 0 !important;
  width: 75px;
  border-color: #253237 !important;
}

.product-carousel-card .view-product-single {
  display: inline-block;
  position: absolute;
  top: 30%;
  opacity: 0;
  -moz-transform: scale3d(0, 0, 0);
  -webkit-transform: scale3d(0, 0, 0);
  -o-transform: scale3d(0, 0, 0);
  -ms-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

.product-carousel-card .view-product-btn-single-icon {
  width: 40px !important;
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 25px !important;
  border: 1px solid #fff !important;
  cursor: pointer;
}

.product-carousel-card:hover .view-product-single {
  /* display: inline-block; */
  opacity: 1;
  -moz-transform: scale3d(1, 1, 1);
  -webkit-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  transition: all 300ms linear;
}

.view-product-btn-single-icon:hover {
  background-color: #4fb68d !important;
  color: #fff !important;
  border-color: #4fb68d !important;
}

.product-carousel-card img {
  transition: 0.5s all ease-in-out;
}

.product-carousel-card:hover img {
  transform: scale(1.1);
}

.out-of-cart-list-btn-list {
  position: relative !important;
  padding: 0 15px !important;
  line-height: 34px !important;
  height: 32px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border: none !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  text-transform: uppercase !important;
  background: orangered !important;
  display: block !important;
  color: #fff !important;
  margin-left: 0px !important;
  cursor: not-allowed !important;
}
