.order-details-user h4 {
  font-size: 16px;
}

.order-details-user {
  margin-bottom: 25px;
}

.order-details-actions-single {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.order-details-actions-single .order-action-items {
  margin-bottom: 10px;
  width: 100%;
}

.order-info {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.product-image-order-page {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  margin-right: 10px;
}

.product-details-order {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.order-details-actions-single .order-action-items {
  margin-bottom: 10px;
  min-width: 200px;
  background-color: #4fb68d !important;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  padding: 5px 0 !important;
  border-color: #4fb68d !important;
}

.order-details-actions-single .order-action-items:hover {
  background-color: #253237 !important;
  color: #fff !important;
  border-color: #253237 !important;
}
