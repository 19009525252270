.background-green{
  /* background-color: #4FB68D; */
  background-image: url("../../assets/images/background-app.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  min-height: 470px;
  border-radius: 45px;
}
.title-app{
  margin-top: 0;
  padding-top: 45px;
  /* padding-left: 85px; */
  text-align: center;
  font-size: 36px;
  color: #fff;
  font-weight: 800;
  line-height: 1.5;
}

.sub-title{
  /* padding-left: 85px;
   */
  text-align: center;
  color: #fff;
  font-size: 24px;
  line-height: 1.8;
}
.play-icon{
  padding-left: 85px;
}
.img{
  vertical-align: middle;
    border-style: none;
    width: 45%;
}
.ml-12{
  margin: 12px !important;
}