.order-details-user h4 {
  font-size: 16px;
  margin-top: 10px;
}

.order-details-user {
  margin-bottom: 25px;
}

.order-details-actions {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 20px;
}

.order-details-actions .order-action-items {
  margin-bottom: 10px;
  min-width: 200px;
  background-color: #4fb68d !important;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  padding: 5px 0 !important;
  border-color: #4fb68d !important;
}

.order-details-actions .order-action-items:hover {
  background-color: #253237 !important;
  color: #fff !important;
  border-color: #253237 !important;
}

.order-info {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.cancel-yes-btn {
  height: 30px !important;
  line-height: 30px !important;
  text-align: center !important;
  background-color: #4fb68d !important;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  padding: 0 !important;
  border-color: #4fb68d !important;
  width: 50px;
}

.cancel-yes-btn:hover {
  background-color: #253237 !important;
  color: #fff !important;
  border-color: #253237 !important;
}

.cancel-no-btn {
  height: 30px !important;
  line-height: 30px !important;
  text-align: center !important;
  background-color: #aaa !important;
  color: #000 !important;
  font-weight: 400 !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  padding: 0 !important;
  border-color: #aaa !important;
  width: 50px;
}

.order-details-container small {
  font-size: 14px !important;
}
