.errorToast {
  background-color: #dc3545 !important;
  color: #fff !important;
}

.successToast {
  background-color: #4fb68d !important;
  color: #fff !important;
}

.toast-header {
  border-bottom: 1px solid #fff !important;
}
