body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    left: 0;
  }
  body .over-menu {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: fixed;
    height: 100%;
    content: "";
    width: 100%;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
  }
  /* body.open-menu {
    left: -250px;
  } */
  body.open-menu .over-menu {
    visibility: visible;
    opacity: 1;
  }
  body.open-menu .menu-container {
    left: 0 !important;
  }
  
  a {
    text-decoration: none !important;
    outline: none;
  }
  
  .hidden {
    display: none;
  }
  
  header {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: transparent;
    width: 100%;
    float: left;
    position: fixed;
    z-index: 10;
  }
  header::before {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    transform-origin: 0;
    background: #FFF;
    position: absolute;
    width: 100%;
    content: "";
    top: 0;
    right: 0;
    height: 0;
  }
  header .desk-menu .menu-container .menu {
    float: left;
    padding: 0;
    margin: 0 20px 0 0;
    list-style: none;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  header .desk-menu .menu-container .menu li.back {
    display: none;
  }
  header .desk-menu .menu-container .menu > li {
    -webkit-transition: all 0.33s ease;
    -moz-transition: all 0.33s ease;
    -ms-transition: all 0.33s ease;
    -o-transition: all 0.33s ease;
    transition: all 0.33s ease;
    margin: 0 0 0 10px;
    float: left;
    cursor: pointer;
    position: relative;
    overflow: inherit;
  }
  header .desk-menu .menu-container .menu > li a {
    position: relative;
    text-transform: capitalize;
    font-size: 13.9px;
    padding: 19px 8px;
    display: block;
    color: #4e4e4e;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children > a {
    padding: 19px 20px 19px 8px;
    position: relative;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children > a::before, header .desk-menu .menu-container .menu > li.menu-item-has-children > a::after {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #4e4e4e;
    position: absolute;
    content: "";
    height: 1px;
    width: 7px;
    top: 26px;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children > a::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    right: 10px;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children > a::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 15px;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu {
    box-shadow: 1px 2px 4px rgba(46, 61, 73, 0.2);
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    -webkit-overflow-scrolling: touch;
    min-width: 200px;
    position: absolute;
    list-style: none;
    background: #FFF;
    padding: 0;
    float: left;
    display: table;
    left: 0;
    width: 100%;
    float: left;
    display: none;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li {
    width: 100%;
    -webkit-transition: all 0.33s ease;
    -moz-transition: all 0.33s ease;
    -ms-transition: all 0.33s ease;
    -o-transition: all 0.33s ease;
    transition: all 0.33s ease;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li > a {
    color: #4e4e4e;
    padding: 12px;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li .sub-menu {
    display: none;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li.menu-item-has-children > a::before, header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li.menu-item-has-children > a::after {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #4e4e4e;
    position: absolute;
    content: "";
    height: 1px;
    width: 7px;
    top: 24px;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li.menu-item-has-children > a::before {
    transform: rotate(45deg);
    right: 6px;
    top: 19px;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li.menu-item-has-children > a::after {
    transform: rotate(-45deg);
    right: 6px;
    top: 23px;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li.menu-item-has-children:hover > a {
    display: block;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li.menu-item-has-children:hover .sub-menu {
    display: block;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li:hover > a::before {
    -webkit-transform: rotate(142deg);
    -moz-transform: rotate(142deg);
    -ms-transform: rotate(142deg);
    -o-transform: rotate(142deg);
    transform: rotate(142deg);
    top: 23px;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li:hover > a::after {
    -webkit-transform: rotate(42deg);
    -moz-transform: rotate(42deg);
    -ms-transform: rotate(42deg);
    -o-transform: rotate(42deg);
    transform: rotate(42deg);
    right: 11px;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu .sub-menu {
    left: -500px;
    margin-top: -43px;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children a {
    text-align: left;
  }
  header .desk-menu .menu-container .menu > li.menu-item-has-children a:hover {
    margin-top: 0;
  }
  header .desk-menu .menu-container .menu > li:hover > a::before {
    -webkit-transform: translateX(5px) rotate(-45deg);
    -moz-transform: translateX(5px) rotate(-45deg);
    -ms-transform: translateX(5px) rotate(-45deg);
    -o-transform: translateX(5px) rotate(-45deg);
    transform: translateX(5px) rotate(-45deg);
    width: 10px;
    right: 12px;
  }
  header .desk-menu .menu-container .menu > li:hover > a::after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 10px;
    right: 7px;
  }
  header .desk-menu .menu-container .menu > li:hover.menu-item-has-children .sub-menu {
    display: block;
  }
  .hamburger-menu {
    display: none;
  }
  header.small::before {
    box-shadow: 0px 5px 25px 0 rgba(46, 61, 73, 0.2);
    height: 100%;
  }
  header.small .desk-menu .menu-container .menu > li.menu-item-has-children > a::before, header.small .desk-menu .menu-container .menu > li.menu-item-has-children > a::after {
    background-color: #4e4e4e;
  }
  header.small .desk-menu .box-menu {
    padding: 0;
  }
  header.small .desk-menu .box-menu ul li a {
    color: #4e4e4e;
  }
  .sc-back li:first-child {
    display: none !important;
  }
  .hm-icon {
    display: flex;
}
  @media (max-width: 991px) {
    .hamburger-menu {
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      display: block;
      position: relative;
      top: 2px;
      bottom: 0;
      margin: auto;
      width: 40px;
      height: 40px;
      cursor: pointer;
      right: -5px;
      z-index: 11;
    }
    .hamburger-menu span {
      text-transform: uppercase;
      left: calc(-100% + -5px);
      padding: 8px 9px 8px 0;
      top: calc(50% - 18px);
      position: absolute;
      font-size: 13px;
      color: #FFF;
    }
    .hamburger-menu .bar,
    .hamburger-menu .bar::after,
    .hamburger-menu .bar::before {
      width: 35px;
      height: 3px;
    }
    .hamburger-menu .bar {
      position: relative;
      -webkit-transform: translateY(25px);
      -moz-transform: translateY(25px);
      -ms-transform: translateY(25px);
      -o-transform: translateY(25px);
      transform: translateY(25px);
      -webkit-transition: all 0.1s ease;
      -moz-transition: all 0.1s ease;
      -ms-transition: all 0.1s ease;
      -o-transition: all 0.1s ease;
      transition: all 0.1s ease;
      background: #4e4e4e;
      top: -7px;
    }
    .hamburger-menu .bar::before, .hamburger-menu .bar::after {
      position: absolute;
      background: #4e4e4e;
      content: "";
      left: 0;
      border-radius: 5px;
    }
    .hamburger-menu .bar::before {
      bottom: 10px;
      -webkit-transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -moz-transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -ms-transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -o-transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .hamburger-menu .bar::after {
      top: 10px;
      -webkit-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -moz-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -ms-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -o-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .hamburger-menu .bar.animate {
      background: rgba(255, 255, 255, 0);
    }
    .hamburger-menu .bar.animate::after {
      top: 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -moz-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -ms-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -o-transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .hamburger-menu .bar.animate::before {
      bottom: 0;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -moz-transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -ms-transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -o-transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
      transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    header .desk-menu .box-menu ul li a {
      color: #FFF !important;
      border-bottom: 1px solid #eaeaea;
    }
    header .desk-menu .menu-container {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      background: #FFF;
      position: fixed;
      height: 100%;
      width: 250px;
      left: -250px;
      top: 0;
      overflow: auto;
    }
    header .desk-menu .menu-container .menu-header-container {
      position: relative;
      float: left;
    }
    header .desk-menu .menu-container .menu-header-container ul {
      padding: 0px !important;
    }
    header .desk-menu .menu-container .menu {
      margin: 0;
    }
    header .desk-menu .menu-container .menu li.back {
      position: relative;
      display: block;
    }
    header .desk-menu .menu-container .menu li.back a {
      padding: 12px 12px 12px 35px !important;
    }
    header .desk-menu .menu-container .menu li.back a::before, header .desk-menu .menu-container .menu li.back a::after {
      background-color: #4e4e4e;
      position: absolute;
      content: "";
      height: 2px;
      width: 7px;
      top: 23px;
    }
    header .desk-menu .menu-container .menu li.back a::before {
      -webkit-transform: rotate(-45deg) !important;
      -moz-transform: rotate(-45deg) !important;
      -ms-transform: rotate(-45deg) !important;
      -o-transform: rotate(-45deg) !important;
      transform: rotate(-45deg) !important;
      top: 20px !important;
      right: inherit !important;
      left: 15px !important;
    }
    header .desk-menu .menu-container .menu li.back a::after {
      -webkit-transform: rotate(45deg) !important;
      -moz-transform: rotate(45deg) !important;
      -ms-transform: rotate(45deg) !important;
      -o-transform: rotate(45deg) !important;
      transform: rotate(45deg) !important;
      top: 24px !important;
      left: 15px !important;
    }
    header .desk-menu .menu-container .menu > li {
      width: 100%;
      float: left;
      margin: 0;
      text-align: left;
    }
    header .desk-menu .menu-container .menu > li a {
      font-family: "Ubuntu", sans-serif;
      padding: 12px;
      color: #4e4e4e !important;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children {
      position: initial;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children a {
      padding: 12px;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children a::before, header .desk-menu .menu-container .menu > li.menu-item-has-children a::after {
      background: #4e4e4e;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children a::before {
      right: 15px !important;
      top: 20px !important;
      width: 7px !important;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children a::after {
      top: 24px !important;
      width: 7px !important;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu {
      box-shadow: none;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      display: block !important;
      background: #FFF;
      z-index: 1;
      top: 0;
      left: -250px;
      height: 100%;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu .sub-menu {
      margin-top: 0;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu.open-sub {
      left: 0%;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li.menu-item-has-children > a::before, header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li.menu-item-has-children > a::after {
      background-color: #4e4e4e;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li.menu-item-has-children > a::before {
      -webkit-transform: rotate(45deg) !important;
      -moz-transform: rotate(45deg) !important;
      -ms-transform: rotate(45deg) !important;
      -o-transform: rotate(45deg) !important;
      transform: rotate(45deg) !important;
      right: 15px !important;
      top: 22px !important;
      width: 7px !important;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li.menu-item-has-children > a::after {
      -webkit-transform: rotate(-45deg) !important;
      -moz-transform: rotate(-45deg) !important;
      -ms-transform: rotate(-45deg) !important;
      -o-transform: rotate(-45deg) !important;
      transform: rotate(-45deg) !important;
      right: 15px !important;
      top: 26px !important;
      width: 7px !important;
    }
    header .desk-menu .menu-container .menu > li:hover a:before {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      right: 6px;
      top: 22px;
    }
    header .desk-menu .menu-container .menu > li:hover a::after {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      right: 15px;
      top: 26px;
    }
    /* .hamburger-menu {
      top: 31px;
    } */
    .hamburger-menu span {
      color: #4e4e4e;
    }
    .hamburger-menu .bar {
      background: #4e4e4e;
    }
    .hamburger-menu .bar::before, header.small .hamburger-menu .bar::after {
      background: #4e4e4e;
    }
    .hamburger-menu .bar.animate {
      background: rgba(255, 255, 255, 0);
    }
  }
  @media (max-width: 767px) {
    header .desk-menu .menu-container .menu li a {
      padding: 15px 12px;
    }
    header .desk-menu .menu-container .menu li.back a {
      padding: 15px 12px 15px 35px !important;
    }
    header .desk-menu .menu-container .menu li.back a::before {
      top: 22px !important;
    }
    header .desk-menu .menu-container .menu li.back a::after {
      top: 26px !important;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children a {
      padding: 15px 12px;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children a::before {
      top: 18px;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children a::after {
      top: 22px;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li > a {
      padding: 15px 12px;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li.menu-item-has-children > a::before {
      top: 18px;
    }
    header .desk-menu .menu-container .menu > li.menu-item-has-children .sub-menu li.menu-item-has-children > a::after {
      top: 22px;
    }
  }
  @media (max-width: 481px) {
    /* .hamburger-menu {
      top: 6px;
      right: 10px;
    } */
    .hamburger-menu .bar,
    .hamburger-menu .bar::after,
    .hamburger-menu .bar::before {
      width: 30px;
      height: 3px;
    }
    .hamburger-menu .bar::before {
      bottom: 9px;
    }
    .hamburger-menu .bar::after {
      top: 9px;
    }
  }