.header{
  margin-bottom: 10px;
  line-height: 1.5;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
}
.content-text{
  text-align: justify;
  color: #888;
  font-size: 15px;
  font-weight: 400;
  line-height: 2;
  margin: 0;
}
.mt-10{
  margin-top: 10px !important;
}
.mb-20{
  margin-bottom: 20px !important;
}
.mt-70{
  margin-top: 70px !important;
}

.former-area {
  display: block !important;
  text-align: center;
  position: relative;
  padding: 50px 0;
  background: url(../../assets/images/former.png) no-repeat;
  background-size: cover;
  height: 405px !important;
}