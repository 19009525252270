.former-page{
  background-color: rgb(255, 255, 255);
  color: rgb(17, 16, 16);
  padding: 20px;
}
.form-header{
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.former-label{
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 0 !important;
}
.breadcrumb-former{
    text-align: center;
    position: relative;
    padding: 50px 0;
    background: url(../../assets/images/our-former.png) no-repeat;
    background-size: cover;
    height: 400px !important;
}
.breadcrumb-heading{
  font-size: 26px !important;
  color: #000;
  line-height: 1;
  text-transform: capitalize;
  font-family: "here-just" !important;
}