.order-details {
  text-align: center;
  border-top: 5px solid #00d7b9;
  border-bottom: 5px solid #00d7b9;
  background-image: linear-gradient(#b95dd7, #ffb367),
    linear-gradient(#b95dd7, #ffb367);
  background-size: 5px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  padding: 25px;
}

.order-details h4 {
  font-size: 25px;
}

.order-details img {
  height: 75px;
  margin: 15px 0 15px 0;
}
