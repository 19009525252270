.individual-product-name {
  font-size: 24px;
  color: #253237;
  font-weight: 600;
}

.individual-product-category-name {
  font-size: 20px;
  color: #253237;
  font-weight: 600;
}

.individual-product-brand-name {
  font-size: 16px;
  color: #253237;
  font-weight: 600;
}

.individual-product-price-name {
  font-size: 24px;
  color: #253237;
  font-weight: 600;
}

.add-to-cart-individual-btn {
  height: 38px !important;
  line-height: 38px !important;
  text-align: center !important;
  display: inline-block !important;
  background-color: #253237 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  padding: 0 10px 0 10px !important;
  border-color: #253237 !important;
}

.cart-change-individual-product {
  max-width: 150px;
  margin-left: 15px;
}

.custom-quantity-individual-product {
  max-width: 105px;
  height: 32px !important;
  margin-bottom: 0 !important;
  margin-left: 10px !important;
}

.custom-quantity-individual-product:focus {
  color: #495057;
  background-color: #fff;
  border-color: #495057 !important;
  outline: 0;
  box-shadow: none !important;
}

.cart-change-individual-product-input {
  text-align: center;
  height: 32px !important;
  max-width: 60px !important;
  font-size: 14px !important;
}

.cart-change-individual-product .input-group-prepend {
  cursor: pointer;
  height: 32px;
  border-radius: 0 !important;
}

.cart-change-individual-product .input-group-text {
  border-radius: 0 !important;
}

.product-dec-slider-2 {
  display: inline-block;
  float: left;
  margin: 0 40px;
}

.zoompro-wrap.zoompro-2 {
  display: inline-block;
  position: relative;
  width: 100%;
  float: left;
  overflow: hidden;
}

.product-dec-slider-2 .slick-slide img {
  display: inline-block;
  width: 100%;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.product-dec-slider-2 .slick-slide {
  margin: auto;
  text-align: center;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.zoompro-wrap .zoompro-span img {
  width: 100%;
}

.product-dec-slider-2 .slick-slide.slick-active:focus {
  outline: none;
}

.product-dec-slider-2 .slick-slide.slick-active.active img {
  border: 1px solid#4fb68d;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.product-dec-slider-2 .slick-slide:hover img {
  border-color: #4fb68d;
}

.product-dec-slider-2 .slick-slide img {
  width: 100px;
  height: 100px;
  margin: 0px 10px 0px;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
  border: 1px solid#fff;
}

.product-details-img.product-details-tab {
  display: flex;
  flex-direction: column;
}

/* .product-details-img img {
  max-height: 450px;
} */

.product-details-content h2 {
  font-size: 24px;
  color: #253237;
  font-weight: 600;
  margin: 0 0 22px;
  line-height: 46px;
  margin-left: 25px;
}

.reference {
  font-size: 16px;
  color: #253237;
  font-weight: 700;
}

.reference span {
  font-weight: 400;
}

.pro-details-rating-wrap {
  display: flex;
  margin: 10px 0 0;
}

.pro-details-rating-wrap .rating-product {
  margin-right: 20px;
}

.read-review a.reviews:before {
  content: "\f4ad";
}

.read-review a.reviews:before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  margin-right: 6px;
}

.read-review a.reviews {
  margin-right: 9px;
}

.read-review a {
  text-decoration: none;
  color: #253237;
  position: relative;
  margin-right: 8px;
  line-height: 21px;
  padding: 0;
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
}

.read-review a:hover {
  color: #4fb68d;
}

.product-details-content .old-price {
  font-size: 22px;
  margin: 20px 0;
}

.pro-details-list {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid#ebebeb;
}

.pro-details-color-wrap {
  font-size: 14px;
  font-weight: 700;
  color: #253237;
}

.pro-details-color-content li {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  margin-right: 15px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  background-size: contain;
  background: #fff;
}

.pro-details-color-content li.active {
  background-color: #434a54;
}

.pro-details-color-wrap span {
  display: block;
  margin: 0 0 10px;
}

.pro-details-color-content li:hover {
  border: 2px solid#434A54;
}

.product-details-content .pro-details-quality .cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #8f8f8f;
  float: left;
  font-size: 14px;
  height: 48px;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 80px;
  outline: none;
}

.product-details-content .pro-details-quality .cart-plus-minus .dec.qtybutton {
  height: 48px;
  left: 0;
  padding-top: 14px;
  top: 0;
}

.product-details-content .pro-details-quality .cart-plus-minus .inc.qtybutton {
  height: 48px;
  padding-top: 14px;
  right: 0;
  top: 0;
}

.product-details-content .pro-details-quality .cart-plus-minus .qtybutton {
  color: #8f8f8f;
  cursor: pointer;
  float: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 24px;
}

.product-details-content .pro-details-quality .cart-plus-minus {
  border: none !important;
  display: inline-block;
  height: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}

.pro-details-cart button {
  position: relative;
  padding: 0 15px;
  line-height: 34px;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  border: none;
  border-radius: 2px;
  box-shadow: none;
  text-transform: uppercase;
  background: #4fb68d;
  display: block;
  color: #fff;
  margin-left: 10px;
}

.home-cosmatics .pro-details-cart a {
  background: #c0b07d;
}

.home-electronic .pro-details-cart a {
  background: #0090f0;
}

.home-furniture .pro-details-cart a {
  background: #ef1e1e;
}

.home-medical .pro-details-cart a {
  background: #0bbfbd;
}

.home-cosmatics .pro-details-social-info .social-info li a:hover {
  color: #c0b07d;
}

.home-electronic .pro-details-social-info .social-info li a:hover {
  color: #0090f0;
}

.home-furniture .pro-details-social-info .social-info li a:hover {
  color: #ef1e1e;
}

.home-medical .pro-details-social-info .social-info li a:hover {
  color: #0bbfbd;
}

.pro-details-quality {
  display: inline-flex;
  margin: 0 0;
  width: 100%;
}

.product-size {
  margin-left: 20px;
}

.product-size span {
  display: block;
  color: #253237;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 7px;
}

.product-size .nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border: solid 1px #e8e8e8;
  height: 30px;
  line-height: 30px;
  padding-right: 30px;
  width: 60px;
  border-radius: 0;
}

.product-size .nice-select .list {
  width: 100%;
  border-radius: 0px;
}

.pro-details-quality.mt-0px {
  margin-top: 0;
}

.product-details-table .table tbody tr td {
  padding: 10px 15px;
  width: 33.33%;
  text-align: center;
  border: 1px solid #ebebeb;
  vertical-align: middle;
}

.product-details-table .table tbody tr td a {
  color: #888;
}

.product-details-table.table-responsive.pro-details-quality {
  display: block;
  margin: 0;
}

.pro-details-cart button:hover {
  background: #253237;
  color: #fff;
  text-decoration: none !important;
}

.pro-details-wish-com {
  display: flex;
}

.pro-details-wish-com a {
  line-height: 30px;
  padding: 0;
  border: none;
  color: #555;
  font-size: 14px;
  margin-right: 15px;
}

.pro-details-wish-com a i {
  margin-right: 10px;
}

.pro-details-wish-com a:hover {
  color: #4fb68d;
}

.pro-details-social-info .social-info li a:hover {
  color: #4fb68d;
}

.pro-details-social-info .social-info li a {
  display: inline-block;
  line-height: 30px;
  height: 30px;
  text-align: center;
  color: #b4b4b4;
  margin-right: 0px;
  white-space: normal;
  text-indent: 0;
  overflow: hidden;
  padding: 0;
  font-size: 20px;
  width: 30px;
}

.pro-details-social-info {
  display: flex;
  border-bottom: 1px solid#ebebeb;
  margin-top: 10px;
  padding-bottom: 12px;
}

.pro-details-social-info span {
  display: inline-block;
  float: left;
  margin-right: 10px;
  line-height: 30px;
  font-weight: 700;
  color: #253237;
  font-size: 14px;
}

.pro-details-social-info .social-info {
  margin-top: 2px;
}

.pro-details-policy {
  margin: 20px 0 0;
  border-top: 1px solid #ebebeb;
  padding-top: 15px;
}

.pro-details-policy img {
  margin-right: 15px;
}

.pro-details-policy ul {
  padding-left: 0 !important;
}

.pro-details-policy li {
  margin-bottom: 20px;
  list-style: none !important;
}

.pro-details-policy li:last-child {
  margin-bottom: 0px;
}

.description-review-area {
  margin-top: 50px;
  margin-left: 15px;
}

.description-review-bottom {
  overflow: hidden;
  font-size: 15px;
  background: #fff;
  line-height: 24px;
  text-align: left;
  padding: 0px;
  margin-top: 15px;
  /* border: 1px solid #ebebeb; */
}

.description-review-topbar.nav {
  border-bottom: none;
  position: relative;
  display: block;
  margin-bottom: 0;
  margin: auto;
  text-align: left;
  margin-top: -6px;
}

.description-review-topbar.nav a {
  background: transparent;
  border: 0;
  text-transform: uppercase;
  line-height: 24px;
  color: #888;
  padding: 0 0 15px 0;
  margin: 0 20px 0 0;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
  display: inline-block;
  cursor: pointer;
}

.description-review-topbar.nav a:after {
  content: "";
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  background: transparent;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.description-review-topbar.nav a.active:after {
  background: #4fb68d;
  color: #253237;
}

.description-review-topbar.nav a.active {
  color: #253237;
}

.description-review-topbar.nav a:hover {
  color: #253237;
  text-decoration: none;
}

.description-review-bottom .product-anotherinfo-wrapper ul li {
  /* color: #888; */
  font-size: 16px;
  /* list-style: outside none none; */
  margin: 0 0 13px;
}

.description-review-bottom .product-anotherinfo-wrapper ul li span {
  color: #253237;
  display: inline-block;
  font-weight: 500;
  margin: 0 26px 0 0;
  min-width: 85px;
}

.description-review-bottom .product-description-wrapper p {
  margin: 0 0 8px;
  font-size: 15px;
  line-height: 28px;
  color: #888;
  width: 84%;
}

.description-review-bottom .product-description-wrapper p:last-child {
  margin: 0 0 0px;
}

.single-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 50px;
}

.single-review .review-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  margin: 0 15px 0 0;
}

.single-review .review-top-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-review .review-top-wrap .review-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.single-review .review-top-wrap .review-left .review-name {
  margin-right: 25px;
}

.single-review .review-top-wrap .review-left .review-name h4 {
  font-size: 16px;
  margin: 0;
  line-height: 1;
  font-weight: 500;
}

.single-review .review-top-wrap .review-left .rating-product {
  line-height: 1;
}

.single-review .review-bottom p {
  margin: 0;
  width: 93%;
}

.single-review.child-review {
  margin-left: 70px;
  margin-bottom: 0;
}

.ratting-form-wrapper {
  padding-left: 50px;
}

.ratting-form-wrapper h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}

.ratting-form-wrapper .ratting-form form .star-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 6px 0 20px;
}

.ratting-form-wrapper .ratting-form form .star-box span {
  margin: 0 15px 0 0;
}

.ratting-form-wrapper .ratting-form form .star-box .ratting-star {
  font-size: 12px;
  color: #fdd835;
  margin: 2px 0 0;
}

.ratting-form-wrapper .ratting-form form .rating-form-style input,
.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  padding: 2px 10px 2px 20px;
  background: transparent;
  border: 1px solid #e6e6e6;
  color: #333;
}

.rating-form-style {
  margin-bottom: 10px;
}

.rating-form-style input {
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #253237;
  width: 100%;
  margin-bottom: 15px;
  outline: none;
}

.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  height: 180px;
  padding: 20px 10px 2px 20px;
  margin-bottom: 20px;
  width: 100%;
  outline: none;
}

.review-left a {
  color: #253237;
}

.review-left a:hover {
  color: #4fb68d;
}

.rating-form-style input[type="submit"] {
  padding: 0 55px !important;
  line-height: 48px;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-radius: 30px;
  box-shadow: none;
  text-transform: uppercase;
  background: #4fb68d !important;
  display: block;
  color: #fff !important;
  width: 200px;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.rating-form-style input[type="submit"]:hover {
  background: #253237 !important;
  color: #fff !important;
}

.product-details-tab-2 #gallery {
  flex: 0 0 20%;
  width: 20%;
}

.product-details-tab-2 .zoompro-wrap.zoompro-2 {
  flex: 0 0 80%;
  width: 80%;
}

.product-details-tab-2 .slick-slide img {
  width: 100px;
  margin: auto;
  text-align: center;
}

.product-details-img.product-details-tab.product-details-tab-2 {
  flex-direction: row;
}

.product-details-img.product-details-tab.product-details-tab-2.product-details-tab-3 {
  flex-direction: row-reverse;
}

.product-details-img.product-details-tab.product-details-tab-2 .slick-vertical .slick-slide {
  margin: 10px 0;
}

.product-details-img.product-details-tab.product-details-tab-2 .slick-vertical .slick-slide:focus {
  border: 1px solid#4fb68d;
  outline: 0;
}

.single-product-slider-item {
  width: 50%;
}

.single-product-gallery-list .single-product-slider-item {
  width: 100%;
}

.single-product-slider-active .single-product-slider-item {
  width: 100%;
}

.product-details-content.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 85px;
  left: 0;
  width: 100%;
}

.single-product-slider-item img {
  width: 100%;
}

.product-details-content .pricing-meta ul {
  padding-left: 0 !important;
  border-bottom: 1px solid #ebebeb;
}

.product-details-content .pricing-meta li {
  list-style: none !important;
}

.add-to-cart-single-container {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.product_price_unit_single {
  max-width: 105px;
  /* margin-bottom: 25px; */
}

.product-description {
  text-indent: 25px;
}

.product-detail-content {
  margin: 10px;
  padding: 20px;
  /* font-size: 14px !important; */
}

.breadcrumb-hrading {
  font-size: 18px !important;
}

.out-of-cart-list-btn-list-single {
  position: relative !important;
  padding: 0 15px !important;
  line-height: 34px !important;
  height: 32px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border: none !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  text-transform: uppercase !important;
  background: orangered !important;
  display: block !important;
  color: #fff !important;
  margin-left: 0px !important;
  cursor: not-allowed !important;
  width: max-content !important;
}

.display-inline {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  flex-direction: row;
  list-style: none;
}

.form-control-sm {
  font-size: 14px;
  /* padding: 1.15rem 0.5rem !important; */
}

.carousel2 .slide img {
  width: 100% !important;
  border-radius: 0% !important;
}

.carousel .slide img {}

.skel {
  min-height: 200px !important;
}