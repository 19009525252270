.address-cards .card {
  margin-bottom: 15px;
}
.palce-order-btn {
  background-color: #4fb68b !important;
  color: #fff !important;
  display: block !important;
  font-weight: 700 !important;
  letter-spacing: 1px !important;
  line-height: 1 !important;
  padding: 18px 20px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  border-radius: 50px !important;
  z-index: 9 !important;
  border: solid 1px #fff !important;
}
.checkout-page .card {
  margin-bottom: 15px;
}
.acc-btn-container .btns {
  margin-left: 10px;
}
.selected-address {
  border: 2px solid green !important;
}
.complete-order-btn {
  margin-left: 10px;
}
.checkout-page .card-body {
  padding: 15px 0 0 0 !important;
}
.checkout-page .row {
  margin: 0 !important;
}
.checkout-page .cardbody-with-padding-full .card-body {
  padding: 15px !important;
}
.address-cards .card-body {
  padding: 15px !important;
}
.add-new-address-btn {
  margin-left: 10px;
}
.payment-select-div {
  padding: 15px;
}
.full-width-div {
  width: 100%;
}
.order-text-div {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 500;
}
.select-address-title {
  margin-left: 10px;
}
.cart-notify-container {
  text-align: center;
  border-top: 5px solid #00d7b9;
  border-bottom: 5px solid #00d7b9;
  background-image: linear-gradient(#b95dd7, #ffb367),
    linear-gradient(#b95dd7, #ffb367);
  background-size: 5px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
  padding: 25px;
}
.cart-notify-container h4 {
  font-size: 25px;
}
.cart-notify-container img {
  height: 75px;
  margin: 15px 0 15px 0;
}
.delete-address-btn {
  background-color: orangered !important;
  color: #fff;
  float: right;
  font-size: 10px !important;
  padding: 2px 4px !important;
  border: 1px solid orangered !important;
}
.your-order-area h3 {
  font-weight: 700;
  color: #253237;
  margin: 0 0 30px;
  font-size: 24px;
  line-height: 16px;
}
.your-order-area .your-order-wrap {
  padding: 20px;
  background: #f6f6f6;
}
.your-order-area .your-order-wrap .your-order-top ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.your-order-area .your-order-wrap .your-order-top ul li {
  font-size: 16px;
  font-weight: 700;
  list-style: outside none none;
  color: #253237;
  margin-right: 25px;
}
.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #dee0e4;
  border-top: 1px solid #dee0e4;
  margin: 29px 0;
  padding: 19px 0 18px;
}
.order-price {
  text-align: left !important;
}
.your-order-area .your-order-wrap .your-order-middle ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-right: 20px;
}
.your-order-area .your-order-wrap .your-order-bottom ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.your-order-bottom > ul > li {
  font-size: 16px;
  color: #253237;
  font-weight: 600;
  list-style: none;
  margin-right: 20px;
}
.your-order-area .your-order-wrap .your-order-total {
  border-bottom: 1px solid #dee0e4;
  border-top: 1px solid #dee0e4;
  margin: 18px 0 33px;
  padding: 17px 0 19px;
}
.your-order-area .your-order-wrap .your-order-total ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-right: 20px;
}
.your-order-area .your-order-wrap .your-order-total ul li.order-total {
  font-weight: 700;
  color: #212121;
  font-size: 18px;
}
.your-order-area .your-order-wrap .your-order-total ul li {
  font-weight: 700;
  color: #4fb68b;
  font-size: 16px;
  list-style: outside none none;
}
.add-new-address-btn {
  float: right;
  background-color: #4fb68b !important;
  border-radius: 50px !important;
  color: #fff !important;
  display: inline-block;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1 !important;
  padding: 15px 40px 15px !important;
  text-transform: uppercase;
  border: solid 1px #fff !important;
}
.addres-head {
  font-size: 16px !important;
  font-weight: 700;
}
.add-card {
  display: flex;
  justify-content: space-between;
}
.payment-method-body {
  padding: 65px;
  font-size: 18px;
  text-align: center;
}
.card {
  margin-top: -15px !important;
}
.otp-popup-footer {
  margin: 0 !important;
  background-color: #fff !important;
}
.form-check-label{
  font-size: 16px;
}