.breadcrumb-hrading {
  color: #253237;
  font-weight: 600;
  font-size: 30px;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-family: "here-just" !important;
}

.breadcrumb-links li a:after {
  font-size: 13px;
  line-height: 24px;
  display: block;
  font-weight: 400;
  top: 0px;
  right: -20px;
  position: absolute;
  content: "\1F872";
  /* font-family: "Ionicons"; */
}

.breadcrumb_container .breadcrumb ol li:after {
  color: #253237;
  margin: 0 10px;
  vertical-align: -1px;
}

.breadcrumb-links li {
  display: inline-block;
}

.breadcrumb-links li a {
  display: inline-block;
  position: relative;
  color: #253237;
  line-height: 20px;
  margin-right: 25px;
}

.breadcrumb-links li a:hover {
  color: #4fb68d;
}
.privacy-area {
  margin-top: 50px;
  margin-bottom: 50px;
}
.header-page {
  font-weight: 700;
}

.privacy-area p {
  text-indent: 50px;
}
