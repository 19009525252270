.address-cards .card {
  margin-bottom: 15px;
}

.checkout-page .acc-btn-container {
  padding: 0.75rem 1.25rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.checkout-page .card {
  margin-bottom: 15px;
}

.acc-btn-container .btns {
  margin-left: 10px;
}

.checkout-page .card-body {
  padding: 15px 0 0 0 !important;
}

.checkout-page .row {
  margin: 0 !important;
}

.checkout-page .cardbody-with-padding-full .card-body {
  padding: 15px !important;
}

.address-cards .card-body {
  padding: 15px !important;
}

.select-address-title {
  margin-left: 10px;
}

.delete-address-btn {
  background-color: orangered !important;
  color: #fff !important;
  float: right;
  font-size: 10px !important;
  padding: 2px 4px !important;
  border: 1px solid orangered !important;
}

.edit-address-btn {
  background-color: #007bff !important;
  color: #fff !important;
  float: right;
  font-size: 10px !important;
  padding: 2px 4px !important;
  border: 1px solid #007bff !important;
  margin-left: 5px !important;
}
