.blog-page-side-heading h2 {
  font-size: 20px;
  line-height: 1;
  color: #253237;
  padding-bottom: 20px;
  margin-bottom: 15px;
  font-weight: 600;
  position: relative;
  display: block;
  border-bottom: 1px solid #ebebeb;
  text-transform: capitalize !important;
}

.blog-page-side-heading h2::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  background: #4fb68d;
}

.category-post ul {
  list-style: none;
  padding-left: 0;

}

.category-post ul li a {
  font-size: 16px;
  color: #666 !important;
  line-height: 36px;
  margin-bottom: 10px;
}

.comment-area .review-content {
  padding: 30px;
  border: 1px solid #ebebeb;
}

.comment-area {
  margin-top: 35px;
}

.single-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 50px;
}

.single-review .review-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  margin: 0 15px 0 0;
}

.single-review .review-top-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-review .review-top-wrap .review-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.single-review .review-top-wrap .review-left .review-name {
  margin-right: 25px;
}

.single-review .review-top-wrap .review-left .review-name h4 {
  font-size: 16px;
  margin: 0;
  line-height: 1;
  font-weight: 500;
}

.single-review .review-top-wrap .review-left .rating-product {
  line-height: 1;
}

.single-review .review-bottom p {
  margin: 0;
  width: 93%;
}

.single-review.child-review {
  margin-left: 70px;
  margin-bottom: 0;
}

.comment-area .single-review.child-review {
  margin-bottom: 70px;
}

.comment-heading {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  color: #253237;
  text-transform: capitalize;
  line-height: 16px;
  font-weight: 600;
  margin: 0 0 40px;
}

.blog-comment-form .comment-heading {
  margin: 0 0 10px;
}

.blog-comment-form p {
  margin: 0 0 30px;
}

.comment-area .single-review .review-top-wrap .review-left .review-name h4 {
  font-weight: 600;
  color: #253237;
}

.breadcrumb-area-blog {
  text-align: center;
  position: relative;
  padding: 110px 0;
  background: url(../../assets/images/blog.png) no-repeat;
  background-size: cover;
  min-height: 395px;
  color: #fff !important;
}