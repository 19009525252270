.w-60{
  width: 60%!important;
}
.skill h6{
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 20px;
  font-weight: 600;
  color: #155e45;
}
.header-certification{
  line-height: 1.4;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  margin-bottom: 15px !important;
}

.certificate-item{
  background-color: #EAF8F5;
  padding: 20px;
  border-radius: 5px;
  margin: 10px;
}