.list-product-card {
  position: relative;
  background: #fff;
  z-index: 1;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
}

.list-product-card:hover {
  border-color: #4fb68d;
}

.list-product-card .inner-link {
  font-size: 12px;
  text-transform: uppercase;
  color: #999;
  line-height: 1;
  margin-bottom: 10px;
  display: block;
}

.list-product-card .inner-link:hover {
  color: #999;
}

.list-product-card .product-link {
  line-height: 18px;
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
  color: #253237;
  font-size: 14px;
  text-transform: capitalize;
}

.list-product-card .product-link:hover {
  color: #4fb68d;
}

.list-product-card .current-price {
  color: #009688;
  font-weight: 400;
  margin-right: 2px;
  font-size: 14px;
}

.list-product-card .input-group-prepend {
  cursor: pointer;
}

.cart-change-list-product-input {
  text-align: center;
}

.custom-list-individual-product {
  margin-right: 5px;
}

.add-to-cart-list-btn {
  height: 38px !important;
  line-height: 38px !important;
  text-align: center !important;
  display: inline-block !important;
  background-color: #253237 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  padding: 0 10px 0 10px !important;
  border-color: #253237 !important;
}

.product_price {
  margin-bottom: 15px;
  margin-top: 10px;
}

.view-product-btn-single {
  height: 30px !important;
  line-height: 30px !important;
  text-align: center !important;
  visibility: hidden !important;
  background-color: #4fb68d !important;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  padding: 0 !important;
  border-color: #4fb68d !important;
  width: 100%;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease,
    -webkit-transform 0.3s ease !important;
}

.list-product-card-main .current-price {
  margin-bottom: 0 !important;
}

.view-product-btn-single:hover {
  background-color: #253237 !important;
  color: #fff !important;
  border-color: #253237 !important;
}

.list-product-card-main:hover .view-product-btn-single {
  visibility: visible !important;
  transform: translateY(-60%);
}

.list-product-card-main img {
  transition: 0.5s all ease-in-out;
}

.list-product-card-main:hover img {
  transform: scale(1.1);
}

.list-product-card-main .card-body {
  margin-bottom: 0 !important;
}

.list-product-card-main .view-product-single {
  display: inline-block;
  position: absolute;
  top: 30%;
  opacity: 0;
  -moz-transform: scale3d(0, 0, 0);
  -webkit-transform: scale3d(0, 0, 0);
  -o-transform: scale3d(0, 0, 0);
  -ms-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

.list-product-card-main .view-product-btn-single-icon {
  width: 40px !important;
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 25px !important;
  border: 1px solid #fff !important;
  cursor: pointer;
}

.list-product-card-main:hover .view-product-single {
  /* display: inline-block; */
  opacity: 1;
  -moz-transform: scale3d(1, 1, 1);
  -webkit-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  transition: all 300ms linear;
}

.view-product-btn-single-icon:hover {
  background-color: #4fb68d !important;
  color: #fff !important;
  border-color: #4fb68d !important;
}

.custom-quantity-list-product {
  max-width: 105px;
  height: 32px !important;
  margin-bottom: 0 !important;
  margin-left: 0px !important;
}

.custom-quantity-list-product:focus {
  color: #495057;
  background-color: #fff;
  border-color: #495057 !important;
  outline: 0;
  box-shadow: none !important;
}

.add-to-cart-list-btn-list {
  position: relative !important;
  padding: 0 15px !important;
  line-height: 34px !important;
  height: 32px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border: none !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  text-transform: uppercase !important;
  background: #4fb68d !important;
  display: block !important;
  color: #fff !important;
  /* margin-left: 10px !important; */
}

.cart-change-list-product-input {
  text-align: center;
  height: 32px !important;
  max-width: 100% !important;
  font-size: 14px !important;
}

.cart-change-list-product .input-group-prepend {
  cursor: pointer;
  height: 32px;
  border-radius: 0 !important;
}

.cart-change-list-product .input-group-text {
  border-radius: 0 !important;
}

.product-filters .custom-checkbox .custom-control-label::before {
  top: 2px !important;
}

.no-products {
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: 25px;
  color: orangered;
  font-weight: bold;
}

.breadcrumb-hrading {
  font-size: 20px !important;
}

.out-of-cart-list-btn-list {
  position: relative !important;
  padding: 0 15px !important;
  line-height: 34px !important;
  height: 32px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border: none !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  text-transform: uppercase !important;
  background: #7b2409 !important;
  display: block !important;
  color: #fff !important;
  margin-left: 0px !important;
  cursor: not-allowed !important;
}
